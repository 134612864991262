<template>
  <div id="savedVisualizations">
    <p class="savedVisualizations-title">Ваши сохраненные визуализации</p>
    <div class="savedVisualizations-container">
      <div class="savedVisualizations-list">
        <visualizationItem
          v-for="item in visualizationsList"
          :key="item._id"
          :favorites="[item._id]"
          :isTD="item.files3DList.length ? true : false"
          :isVR="item.filesVR ? true : false"
          :type="item.category"
          :title="item.title"
          :preview="item.preview"
          :id="item._id"
        ></visualizationItem>
      </div>
    </div>
  </div>
</template>

<script>
import visualizationItem from "./../visualization/visualization_item.vue";

export default {
  name: "SavedVisualizations",
  components: { visualizationItem },
  data() {
    return {
      endpoint: "profile/favorites",
      visualizationsList: [],
    };
  },
  methods: {
    getSavedVisualizations() {
      this.$http.get(this.endpoint).then((response) => {
        // console.log(response.data);
        if (response.data.favorites) {
          this.visualizationsList = this.visualizationsList.concat(response.data.favorites);
        } else {
          console.log("We can't get favorites!");
        }
      });
    },
  },
  created() {
    this.getSavedVisualizations();
  },
};
</script>

<style lang="sass">
#savedVisualizations
    display: block
    width: 100%
    margin-top: 1.5rem
    .savedVisualizations-title
      font-size: 18px
      margin-bottom: 0.5rem
    .savedVisualizations-container
      display: block
      width: 100%
      padding: 1rem 0 1.5rem
      overflow: auto
      .savedVisualizations-list
          display: flex
          .visualizationItem
              display: flex
              min-height: 150px
              min-width: calc((100% / 4) - 0.5rem)
              max-height: 300px
              max-width: 230px
              margin-right: 0.5rem
</style>
