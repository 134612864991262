<template>
  <div>
    <p class="visualizationCategories-title">Выберите интересующий Вас раздел</p>
    <div class="visualizationCategories">
      <router-link :to="{ name: 'visualization_surgery' }" class="visualizationItem surgeryBg">
        <img
          src="./../../assets/images/visualizations/surgery_and_implantation_logo.png"
          class="visualizationItemLogo"
          alt=""
        />
        <div class="visualizationItemContent">
          Хирургия и <br />
          имплантация
        </div>
      </router-link>
      <router-link :to="{ name: 'visualization_orthodontics' }" class="visualizationItem orthodonticsBg">
        <img src="./../../assets/images/visualizations/orthodontics_logo.png" class="visualizationItemLogo" alt="" />
        <div class="visualizationItemContent">Ортодонтия</div>
      </router-link>
      <router-link :to="{ name: 'visualization_orthopedics' }" class="visualizationItem orthopedicsBg">
        <img src="./../../assets/images/visualizations/orthopedics_logo.png" class="visualizationItemLogo" alt="" />
        <div class="visualizationItemContent">
          Ортопедия, <br />
          протезирование
        </div>
      </router-link>
      <router-link :to="{ name: 'visualization_terapy' }" class="visualizationItem terapyBg">
        <img src="./../../assets/images/visualizations/terapy_logo.png" class="visualizationItemLogo" alt="" />
        <div class="visualizationItemContent">Терапевтическая стоматология и анатомия</div>
      </router-link>
    </div>
    <savedVisualizations v-if="isLoggedIn"></savedVisualizations>
  </div>
</template>
<script>
import savedVisualizations from "./../savedVisualizations/savedVisualizations.vue";

export default {
  name: "Visualization_categories",
  components: { savedVisualizations },
  data() {
    return {};
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style lang="sass">
.visualizationCategories-title
    font-size: 18px
    // font-weight: bold
    margin: 2rem 0 0.5rem
</style>
